<script>
  import { _ } from "svelte-i18n";
  import { createEventDispatcher, onMount, onDestroy } from "svelte";

  export let time = 90;
  let remainingTime = time;
  let countDownTimerObject;
  let countDownTimerText = formatTime(time);

  let recordingButtonState = "starting";

  const dispatch = createEventDispatcher();

  function formatTime(time) {
    return Math.floor(time / 60) + ":" + ((time % 60) + "").padStart(2, "0");
  }

  onMount(async () => {
    countDownTimerText = formatTime(time);

    startTimer()
  });

  onDestroy(() => {
		console.log('the component is being destroyed');

    if (countDownTimerObject){
      clearInterval(countDownTimerObject);
    }
	});
  

  export function startTimer() {
    remainingTime = time;

    countDownTimerObject = setInterval(function () {
      remainingTime = remainingTime-1;

      if (remainingTime < 0) {
        stopTimer();
        countDownTimerText = "Finished";
      } else {
        countDownTimerText = formatTime(remainingTime);
      }
    }, 1000);
  }

  export function stopTimer() {
    remainingTime = 0
    countDownTimerText = formatTime(remainingTime);

    clearInterval(countDownTimerObject);
    console.log("stopButton clicked");

    //disable the stop button, enable the record too allow for new recordings
    recordingButtonState = "stop";

    dispatch("counterStop");
  }

  
</script>

<div class="row">
  <div class="col">
    {$_("REMAINING_TIME")}: <span class="ml-1" style="font-size: xx-large;"
      >{countDownTimerText}</span
    >
  </div>
</div>
