<script>
  import { _ } from "svelte-i18n";
  import { Route, router, meta } from "tinro";
  import { BASE_URL } from "../utilities/config.js";
  import { createEventDispatcher, onMount } from "svelte";
  import { postJsonFile } from "../utilities/Utils.svelte";
  import RangeSlider from "svelte-range-slider-pips";
  import { postJsonFileNoResponse } from "../utilities/Utils.svelte";
  import {
    currentPage,
    skillId,
    cid,
    userId,
    userName,
    assessmentType,
    assessmentId,
    instanceId,
    assessmentState,
    assessmentDefinitionId,    
    activationCode,
  } from "../utilities/DataStore";

  let errorMsg = undefined;
  let loadingState = "NEW";
  let certificationScore = undefined;
  let certificationType = undefined;

  onMount(async () => {
    loadData();
  });

  let assessmentList = [];

  function loadData() {
    loadingState = "LOADING";
    return fetch(
      BASE_URL +
        `/public/assessment/definition`
    )
      .then((response) => {
        if (!response.ok) {
          console.log("response error");

          throw new Error("Network response was not ok");
        }
        console.log("response ok", response);
        return response.json();
      })
      .then((data) => {
        assessmentList = data.filter((a) => a.activationCode === $activationCode);
        console.log("exerciseListData", assessmentList);
        loadingState = "OK";
      })
      .catch((error) => {
        console.log("catch error", error);
        errorMsg = "ERROR_LOADING_ASSESSMENT";
        assessmentList = [];
        loadingState = "ERROR";
      });
  }

  function loadData2() {
    //TODO - Replace with API
    let list = [
      {
        id: 1,
        code: "NTNU_POC_1",
        name: "NTNU POC - 1 (Medical)",
        activationCode: "NTNU_2",
        description: "XXX",
        duration: 15,
      },
      {
        id: 2,
        code: "NTNU_POC_2",
        name: "Speaking Proficiency Test Framework",
        activationCode: "NTNU",
        description:
          "Integrating Academic Literacy and Academic Reading Skills on EMI",
        duration: 20,
      },
      {
        id: 3,
        code: "GJUN_DEMO",
        name: "GJUN - DEMO - 1",
        activationCode: "GJUN",
        description:
          "See how it works first! \n *The score is not going to be representative of your real skills.",
        duration: 5,
      },
      {
        id: 4,
        code: "CRANE_GEPT_1",
        name: "GEPT - 1",
        activationCode: "CRANE",
        description:
          "See how it works first! \n *The score is not going to be representative of your real skills.",
        duration: 15,
      },

      {
        id: 5,
        code: "ENGDEMO",
        name: "Demo",
        activationCode: "DEMO",
        description:
          "See how it works first! \n *The score is not going to be representative of your real skills.",
        duration: 3,
      },
      {
        id: 6,
        code: "INTRO1",
        name: "Intro1",
        activationCode: "LITEON",
        description: "Self Introduction v1",
        duration: 5,
      },
      {
        id: 8,
        ENG2: "ENG1",
        name: "Eng-1",
        activationCode: "",
        description: "Self Advanced Beginner v1 \n CEFR A2 \n 16 Questions",
        duration: 10,
      },
      {
        id: 8,
        ENG2: "ENG2",
        name: "Eng-2",
        activationCode: "",
        description: "Self Introduction v1 \n CEFR B1 \n 17 Questions",
        duration: 15,
      },
    ];

    assessmentList = list.filter((a) => a.activationCode === $activationCode);
  }

  function startAssessment(assessmentTypeValue, assessmentDefinitionIdValue) {
    console.log(555);
    loadingState = "LOADING";

    $assessmentType = assessmentTypeValue;
    $assessmentDefinitionId = assessmentDefinitionIdValue;

    if (assessmentTypeValue === "INTRO1") {
      $currentPage = "IntroductionPage";
    } else if (assessmentTypeValue === "NTNU_POC_1") {
      $currentPage = "IntroductionPage";
    } else if (assessmentTypeValue === "NTNU_POC_2") {
      $currentPage = "IntroductionPage";
    } else if (assessmentTypeValue === "TOEIC_DEMO") {
      $currentPage = "PreviousExam";
    } else {
      $currentPage = "IntroductionPage";
      // $currentPage = "PreviousExam";
    }
  }
</script>

<div class="container">
  <div class="px-4 py-5 my-1 text-center">
    <div class="col-lg-9 mx-auto">
      <div class="mb-4">
        <span
          ><img
            style="margin-right: 1em;"
            src="assets/img/logo_horizontal_nobg2.png"
          /></span
        >
        {#if $activationCode === "NTNU"}
          <span
            ><a href="https://www.obe.ntnu.edu.tw/index.php/en/rcemi/">
              <img src="assets/img/ntnu_emi_logo.png" /></a
            ></span
          >
        {/if}
      </div>

      <h2 class="lead mb-4">{$_("PLEASE_SELECT_ASSESSMENT")}</h2>

      <div class="row row-cols-sm-1 row-cols-md-2 g-3">
        {#each assessmentList as a}
          <div class="col">
            <div class="card shadow-sm mb-4">
              <div class="card-header">{a.name}</div>
              <div class="card-body">
                <p class="card-text">{@html a.description || ''}</p>
                <p class="card-text">{$_("SCORE_NOT_REPRESENTATIVE")}</p>

                <p class="text-muted">
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.5">
                      <path
                        d="M12.49 2C6.97 2 2.5 6.48 2.5 12C2.5 17.52 6.97 22 12.49 22C18.02 22 22.5 17.52 22.5 12C22.5 6.48 18.02 2 12.49 2ZM12.5 20C8.08 20 4.5 16.42 4.5 12C4.5 7.58 8.08 4 12.5 4C16.92 4 20.5 7.58 20.5 12C20.5 16.42 16.92 20 12.5 20ZM12.28 7H12.22C11.82 7 11.5 7.32 11.5 7.72V12.44C11.5 12.79 11.68 13.12 11.99 13.3L16.14 15.79C16.48 15.99 16.92 15.89 17.12 15.55C17.33 15.21 17.22 14.76 16.87 14.56L13 12.26V7.72C13 7.32 12.68 7 12.28 7Z"
                        fill="black"
                        fill-opacity="0.54"
                      />
                    </g>
                  </svg>
                  {a.totalDurationLimit || '5'}
                  {$_("MINS")}
                </p>
              </div>
              <div class="card-footer">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-outline-primary btn-start"
                      on:click={() => startAssessment(a.code, a.id)}
                      >{$_("START_ASSESSMENT")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/each}
      </div>
    </div>
  </div>
</div>

<style>
  .card {
    min-height: 20em;
    background-color: #e8f4f4;
    text-align: left;
  }

  .card-header {
    color: #1e1e1e;
    font-family: "Noto Sans", serif;
    background-color: #e8f4f4;
    border-bottom: none;
    font-size: large;
    font-weight: 700;
  }

  .card-body {
    font-size: small;
    font-weight: 100;
  }

  .card-footer {
    background-color: #e8f4f4;
    border-top: none;
  }

  .btn-start {
    background-color: #00a094;
    color: #ffffff;
  }
</style>
