<script>
  import { _ } from "svelte-i18n";
  import { onMount } from "svelte";
  import { BASE_URL } from "../../utilities/config.js";
  import { currentPage, activationCode } from "../../utilities/DataStore";

  export let instanceId;
  export let instanceData;

  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  let scoreOverall = 50;
  let result = {}


  function initialize() {
    console.log(instanceData);
    if (instanceData) {
      result = JSON.parse(instanceData?.reviewComment);
      scoreOverall = (100 * result?.sumScoreTotal) / result?.sumScoreTotalMax;
    }
    console.log("score", scoreOverall);

    loadDataProgress()
  }

  let exerciseListStatus
  let progressListData

  async function loadDataProgress() {
    exerciseListStatus = "LOADING";
    return fetch(
      BASE_URL + `/public/assessment/instance/` + instanceId + `/progress`
    )
      .then((response) => {
        if (!response.ok) {
          console.log("response error");

          throw new Error("Network response was not ok");
        }
        console.log("response ok", response);
        return response.json();
      })
      .then((data) => {
        progressListData = data;
        console.log("progressListData", progressListData);
        exerciseListStatus = "OK";
      })
      .catch((error) => {
        console.log("catch error", error);
        errorMsg = "ERROR_LOADING_ASSESSMENT";
        progressListData = undefined;
        exerciseListStatus = "ERROR";
      });
  }

  onMount(() => {
    initialize();
  });

  function shareButton() {
    if (navigator.share) {
      navigator
        .share({
          title: "Share Sensay Assessment Report",
          url: `https://assessment.oksensay.com/#/certificate/${instanceId}`,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      copyLink();
    }
  }

  let linkCopied = false;

  let timer;

  const debounce = (v) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      linkCopied = false;
    }, 5000);
  };

  function copyLink() {
    let url = `https://assessment.oksensay.com/#/certificate/${instanceId}`;
    copyTextToClipboard(url);
  }

  function copyTextToClipboard(text) {
    linkCopied = true;
    debounce();
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      },
    );
  }

  function formatTime(time) {
    return Math.floor(time / 60) + ":" + ((Math.round(time) % 60) + "").padStart(2, "0");
  }
</script>

<div class="container paper-shadow">
  <div class="row justify-content-center">
    <div class="col-auto">
      <div class="mb-4">
        <span
          ><a href="https://www.oksensay.com/assessment-project-with-ntnu-rcemi/"
            ><img
              style="margin-right: 1em;"
              src="assets/img/logo_horizontal_nobg2.png"
            /></a
          ></span
        >
        <span
          ><a href="https://www.obe.ntnu.edu.tw/index.php/en/rcemi/">
            <img src="assets/img/ntnu_emi_logo.png" /></a
          ></span
        >
      </div>
    </div>
  </div>

  <div class="row mt-4 justify-content-center">
    <div class="col-auto">
      <h2 class="me-4">You've completed the assessment.</h2>
      <p class="text">
        Congratulations, you've spoken a total of <strong>{result?.sumWordCount}</strong> words in <strong>{formatTime(result?.sumDurationSec)}</strong> minutes. Please note that this score is not your final score. The final result will be recalculated by examiner assessors and the system.
      </p>

      <p>Your preliminary score is: <span class="score">{scoreOverall?.toFixed(0)}%</span> <span style="display:none;"><small>({result?.sumScoreTotal?.toFixed(0)} / {result?.sumScoreTotalMax?.toFixed(0)})</small></span></p>
    </div>

    
  </div>
</div>

<style>
  .text {
    font-size: large;
  }

  .score {
    font-size: xx-large;
    font-weight: 700;
  }

  .paper-shadow {
    position: relative;
    background: #fff;
    padding: 3rem;
    /* width: 50%; */
    margin: 50px auto;
    box-shadow: 0px 2px 38px rgba(0, 0, 0, 0.2);
    /* height: 400px; */
  }
  .paper-shadow:after,
  .paper-shadow:before {
    content: "";
    position: absolute;
    left: auto;
    background: none;
    z-index: -1;
  }
  .paper-shadow:after {
    width: 90%;
    height: 10px;
    top: 30px;
    right: 8px;
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    transform: rotate(-3deg);
    -webkit-box-shadow: 0px -20px 36px 5px #295d92;
    -moz-box-shadow: 0px -20px 36px 5px #295d92;
    box-shadow: 0px -25px 35px 0px rgba(0, 0, 0, 0.5);
  }

  .paper-shadow:before {
    width: 10px;
    height: 95%;
    top: 5px;
    right: 18px;
    -webkit-transform: rotate(3deg);
    -moz-transform: rotate(3deg);
    -o-transform: rotate(3deg);
    -ms-transform: rotate(3deg);
    transform: rotate(3deg);
    -webkit-box-shadow: 20px 0px 25px 5px #295d92;
    -moz-box-shadow: 20px 0px 25px 5px #295d92;
    box-shadow: 22px 0px 35px 0px rgba(0, 0, 0, 0.5);
  }

  hr.hr-text {
    position: relative;
    border: none;
    height: 1px;
    background: #999;
  }

  hr.hr-text::before {
    content: attr(data-content);
    display: inline-block;
    background: #fff;
    font-weight: bold;
    font-size: 0.85rem;
    color: #999;
    border-radius: 30rem;
    padding: 0.2rem 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
