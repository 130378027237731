<script>
  import { _ } from "svelte-i18n";
  import { createEventDispatcher, onMount, onDestroy } from "svelte";
  import { writable } from "svelte/store";
  import {
    lastRecording,
    sharedTimer,
    sharedTimerMode,
  } from "../../utilities/DataStore";

  let recordings = [];
  let recordAudio = [];
  let recordCount = 0;

  export let audioControlDuration = writable([]);

  const workerOptions = {
    OggOpusEncoderWasmPath:
      "https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/OggOpusEncoder.wasm",
    WebMOpusEncoderWasmPath:
      "https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/WebMOpusEncoder.wasm",
  };

  window.MediaRecorder = OpusMediaRecorder;
  let recorder;

  // shim for AudioContext when it's not avb.
  let AudioContext = window.AudioContext || window.webkitAudioContext;
  let audioContext; //audio context to help us record

  let lastFilename;
  let lastBlob;
  let lastAudio;

  // export let time = 90;
  // let mode = "PREPARE" // PREPARE, RECORDING
  // let remainingTime = time;

  let prepareCountDownTimerObject;
  let countDownTimerObject;
  let countDownTimerText = formatTime($sharedTimer);

  let recordingButtonState = "starting";

  const dispatch = createEventDispatcher();

  function formatTime(time) {
    if (time < 0) {
      time = 0;
    }
    return Math.floor(time / 60) + ":" + ((time % 60) + "").padStart(2, "0");
  }

  onMount(async () => {
    countDownTimerText = formatTime($sharedTimer);
    prepare();
    audioControlDuration.subscribe((value) => {
      console.log("audioControlDuration", $audioControlDuration, value);

      dispatch("audioControlDuration", { audioControlDuration: value });
    });
  });

  onDestroy(() => {
    console.log("the component is being destroyed");

    if (prepareCountDownTimerObject){
      clearInterval(prepareCountDownTimerObject);
    }

    if (countDownTimerObject) {
      clearInterval(countDownTimerObject);
    }
  });

  function prepare() {
    prepareCountDownTimerObject = setInterval(function () {
      let c = $sharedTimer-1;
      sharedTimer.set(c);

      if ($sharedTimer < 0) {
        // remainingTime = time;
        // $sharedTimer = -1
        sharedTimerMode.set("STOP");
        clearInterval(prepareCountDownTimerObject);
        dispatch("prepareTimerExpired");
      } else {
        countDownTimerText = formatTime(c);
      }
    }, 1000);
  }

  export function startRecording() {
    // remainingTime = time;
    // mode = "RECORDING";
    clearInterval(prepareCountDownTimerObject);
    sharedTimerMode.set("RECORDING");

    navigator.mediaDevices
      .getUserMedia({ audio: { channelCount: 1 }, video: false })
      .then((stream) => {
        let options = { mimeType: "audio/ogg;codecs=opus" };
        // Start recording
        recorder = new MediaRecorder(stream, options, workerOptions);
        recorder.start();
        // Set record to <audio> when recording will be finished
        recorder.addEventListener("dataavailable", (e) => {
          //   console.log("dataavailable", e);

          createDownloadLink(e.data);
        });
        console.log("Recording started");
        recordingButtonState = "recording";
      })
      .catch(function (err) {
        //enable the record button if getUserMedia() fails
        recordingButtonState = "error";
      });

    if(countDownTimerObject){
      clearInterval(countDownTimerObject)
    }  

    countDownTimerObject = setInterval(function () {
      let c = $sharedTimer-1;
      sharedTimer.set(c);

      if ($sharedTimer < 0) {
        stopRecording();
        countDownTimerText = "Finished";
      } else {
        countDownTimerText = formatTime(c);
      }
    }, 1000);
  }

  export function stopRecording() {
    sharedTimerMode.set("STOP");
    clearInterval(countDownTimerObject);
    console.log("stopButton clicked");

    //disable the stop button, enable the record too allow for new recordings
    recordingButtonState = "stop";

    recorder.stop();
    // Remove “recording” icon from browser tab
    recorder.stream.getTracks().forEach((i) => i.stop());
    dispatch("recordingStop");
  }

  function createDownloadLink(blob) {
    let url = URL.createObjectURL(blob);
    lastBlob = blob;
    //var au = document.createElement('audio');
    //var li = document.createElement('div');

    //name of .wav file to use during upload and download (without extendion)
    let filename = new Date().toISOString();
    lastFilename = filename + ".ogg";

    const newReocrding = { blob: blob, filename: filename, url: url };
    $lastRecording = newReocrding;

    dispatch("recordingAvailable", newReocrding);
  }

  function clickSubmitCalculate() {
    console.log("clickSubmitCalculate");
  }
</script>

<div class="row">
  <!--   
  <div class="col">
    {#if mode === "PREPARE"}{$_("GET_READY_TIME")}{:else}{$_("REMAINING_TIME")}{/if}
    : <span class="ml-1" style="font-size: xx-large;"
      >{countDownTimerText}</span
    >
  </div> -->

  {#if $lastRecording}
    <div class="col" style="display:none;">
      <audio controls="controls" bind:duration={$audioControlDuration}>
        <track kind="captions" />
        <source src={$lastRecording.url} type="audio/wav" />
      </audio>
    </div>
  {/if}
</div>
