<script>
  import { _ } from "svelte-i18n";
  import { Route, router, meta } from "tinro";
  import { BASE_URL } from "../utilities/config.js";
  import { createEventDispatcher, onMount } from "svelte";
  import { postJsonFile } from "../utilities/Utils.svelte";
  import {
    currentPage,
    skillId,
    cid,
    userId,
    userName,
    assessmentType,
    assessmentId,
    instanceId,
    assessmentState,
    assessmentDefinitionId,
    activationCode,
  } from "../utilities/DataStore";

  let errorMsg = undefined;
  let loadingState = "NEW";

  onMount(async () => {
    loadData();
    checkMicrophonePermission();
  });

  let assessmentDetail;

  let microphonePermissionStatus = "new";

  function loadData() {
    loadingState = "LOADING";
    return fetch(
      BASE_URL + `/public/assessment/definition/${$assessmentDefinitionId}`,
    )
      .then((response) => {
        if (!response.ok) {
          console.log("response error");

          throw new Error("Network response was not ok");
        }
        console.log("response ok", response);
        return response.json();
      })
      .then((data) => {
        assessmentDetail = data;
        console.log("assessmentDetail", assessmentDetail);
        loadingState = "OK";
      })
      .catch((error) => {
        console.log("catch error", error);
        errorMsg = "ERROR_LOADING_ASSESSMENT";
        assessmentDetail = undefined;
        loadingState = "ERROR";
      });
  }

  function confirmStart() {
    loadingState = "LOADING";
    startAssessmentInstance(
      $userId,
      $userName,
      $assessmentId,
      $assessmentType,
      $skillId,
      $cid,
    )
      .then((data) => {
        //router.goto("/assessment/" + $instanceId);
        $currentPage = "AssessmentOverview";
      })
      .catch((error) => {
        errorMsg = "Error" + error;
      });
  }

  async function startAssessmentInstance(
    clientId,
    clientName,
    assessmentId,
    assessmentDefinitionCode,
    skillId,
    cid,
  ) {
    const assessmentInstance = {
      clientId: clientId,
      clientName: clientName,
      assessmentId: assessmentId,
      status: "NEW",
      assessmentDefinitionCode: assessmentDefinitionCode,
      skillId: skillId,
      cid: cid,
    };

    await postJsonFile(
      BASE_URL + "/public/assessment/instance/",
      { "Content-Type": "application/json" },
      {},
      assessmentInstance,
    )
      .then((data) => {
        console.log("send data to sensay", data);
        $assessmentDefinitionId = data.assessmentDefinitionId;
        $assessmentState = data.status;
        $instanceId = data.id;

        mixpanel.track("StartInstance", assessmentInstance);
      })
      .catch((error) => {
        errorMsg = error;
        console.error("error sending data to sensay", error);
        throw new Error(error);
      });
  }

  function checkMicrophonePermission() {
    microphonePermissionStatus = "new";
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      // Request microphone access
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          // The user has granted permission and we have a stream
          console.log("Microphone permission granted.");
          // You can now use the stream for audio processing, recording, etc.
          microphonePermissionStatus = "ok";
        })
        .catch((error) => {
          // Permission was denied, or there was some other error
          console.error(
            "Microphone permission denied or error occurred:",
            error,
          );
          microphonePermissionStatus = "error";
        });
    } else {
      console.error("getUserMedia() not supported in this browser.");
      microphonePermissionStatus = "error";
    }
  }
</script>

<div class="container">
  <div class="px-4 py-5 my-1 text-center">
    <!-- <img
      class="d-block mx-auto mb-4"
      src="assets/img/logo_2.png"
      alt=""
      width="200px"
    /> -->

    <div class="mb-4">
      <span
        ><img
          style="margin-right: 1em;"
          src="assets/img/logo_horizontal_nobg2.png"
        /></span
      >
      {#if $activationCode === "NTNU"}
        <span><img src="assets/img/ntnu_emi_logo.png" /></span>
      {/if}
    </div>

    <h1 class="display-5 fw-bold">{$_("READY_TO_START")}</h1>
    <div class="col-lg-9 mx-auto">
      <!-- <h2 class="lead mb-4">{$_("INSTRUCTIONS")}</h2> -->

      <ol>
        <li>{$_("INSTRUCTIONS.PART1")}</li>
        <li>{$_("INSTRUCTIONS.PART2")}</li>
      </ol>

      <!-- <p class="lead mb-4">
        <strong>{$_("TECHNICAL_REQUIREMENTS")}</strong>
        {$_("TECHNICAL_REQUIREMENTS_DETAIL")}
      </p> -->
      {#if assessmentDetail?.instructions}
        <div>
          {@html assessmentDetail?.instructions}
        </div>
      {/if}

      {#if microphonePermissionStatus === "new"}
        <div>{$_("MICROPHONE_CHECKING")}</div>
      {:else if microphonePermissionStatus === "ok"}
        <div>{$_("MICROPHONE_OK")}</div>
      {:else if microphonePermissionStatus === "error"}
        <div>{$_("MICROPHONE_ERROR")}</div>
      {/if}

      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center mt-4">
        {#if loadingState === "OK" && microphonePermissionStatus === "ok"}
          <button
            type="button"
            class="btn btn-primary btn-lg px-4 gap-3"
            on:click={confirmStart}>{$_("START_ASSESSMENT")}</button
          >
        {:else}
          <div class="spinner-border align-center" role="status">
            <span class="sr-only">{$_("LOADING")}</span>
          </div>
        {/if}
      </div>
    </div>
  </div>
</div>

<style>
  h1 {
    font-family: "Noto Sans", serif;
    font-size: xxx-large;
    font-weight: 600;
    margin-top: 1em;
  }

  ol {
    text-align: left;
  }
</style>
