<script>
  import { _ } from "svelte-i18n";
  import { BASE_URL } from "../utilities/config.js";
  import { createEventDispatcher, onMount } from "svelte";
  import {
    userId,
    userName,
    assessmentType,
    assessmentId,
    assessmentState,
    finalScoreDetail,
    activationCode,
    assessmentDefinitionId,
    currentPage,
  } from "../utilities/DataStore";
  import { now } from "svelte/internal";
  import ResultReport from "./components/ResultReport.svelte";
  import ResultReportNoData from "./components/ResultReportNoData.svelte";
  import FeedbackModal from "./components/FeedbackModal.svelte";
  import ResultReportNtnu from "./components/ResultReportNtnu.svelte";
  import {
    submitFinalAssessmentResults,
    callEvaluationService,
  } from "./components/UploadResults.svelte";

  const dispatch = createEventDispatcher();
  onMount(async () => {
    initialize();
  });

  export let instanceId;

  let instanceData;
  let definitionData;
  let exerciseListStatus;
  let definitionStatus;
  let reviewCommentData;

  let errorMsg;

  function initialize() {
    definitionStatus = "LOADING";
    exerciseListStatus = "LOADING";

    Promise.all([loadInstanceData()])
      .then(() => {})
      .catch(() => {
        console.log("reloading");
        setTimeout(function () {
          loadInstanceData();
        }, 1500);
      });
  }

  async function loadDefinitionData(assessmentDefinitionId) {
    definitionStatus = "LOADING";
    return fetch(
      BASE_URL + `/public/assessment/definition/` + assessmentDefinitionId,
    )
      .then((response) => {
        if (!response.ok) {
          console.log("response error");
          throw new Error("Network response was not ok");
        }
        console.log("response ok", response);
        return response.json();
      })
      .then((data) => {
        definitionData = data;
        console.log("definitionData", definitionData);
        definitionStatus = "OK";
      })
      .catch((error) => {
        console.log("catch error", error);
        errorMsg = "ERROR_LOADING_ASSESSMENT";
        definitionData = undefined;
        definitionStatus = "ERROR";
      });
  }

  async function loadInstanceData() {
    exerciseListStatus = "LOADING";
    return fetch(BASE_URL + `/public/assessment/instance/` + instanceId)
      .then((response) => {
        if (!response.ok) {
          console.log("response error");
          throw new Error("Network response was not ok");
        }
        console.log("response ok", response);
        return response.json();
      })
      .then((data) => {
        instanceData = data;
        if (instanceData.reviewComment != null) {
          $finalScoreDetail = JSON.parse(instanceData.reviewComment);
        }

        if (instanceData.status === "NEW") {
          if (new Date() < Date.parse(instanceData?.deadlineAt + "Z")) {
            $assessmentDefinitionId = instanceData?.assessmentDefinitionId;
            $assessmentId = instanceData?.assessmentId;
            $userName = instanceData?.clientName;
            $userId = instanceData?.clientId;
            $currentPage = "AssessmentExercise";

            dispatch("resumeAssessment", {});
            throw new Error("Assesment not yet scored");
          } else {
            console.log("EXPIRED - TODO");
            submitFinalResult()
            throw new Error("Assesment not yet scored");
          }
        } else if (instanceData.status != "SCORED") {
          throw new Error("Assesment not yet scored");
        }

        console.log(
          Date.parse(instanceData?.createdAt) /
            Date.parse(instanceData?.deadlineAt),
        );
        console.log("instanceData", instanceData);
        exerciseListStatus = "OK";
        loadDefinitionData(instanceData.assessmentDefinitionId);
      })
      .catch((error) => {
        console.log("catch error", error);
        errorMsg = "ERROR_LOADING_ASESSMENT_INSTANCE";
        instanceData = undefined;
        //exerciseListStatus = "ERROR";
        setTimeout(function () {
          loadInstanceData();
        }, 10000);
        // throw new Error("Some Error, try again");
      });
  }

  function submitFinalResult() {
    console.log("submitFinalResults");
    // submitFinalAssessmentResultsStatus = "LOADING";
    submitFinalAssessmentResults(instanceId).then(async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      callEvaluationService($assessmentDefinitionId, instanceId).then(
        (data) => {
          $assessmentState = "DONE";
          $currentPage = "AssessmentResults";
          $finalScoreDetail = data;
          router.goto('/certificate/'+instanceId);
        }
      );
    });
  }
</script>

<div class="container">
  <div class="row align-items-center" style="display: none;">
    <div class="col">
      <div class="lesson-title">
        {$assessmentType}
      </div>
    </div>
    <div class="col-auto lesson-detail-box">
      <div class="lesson-detail float-right">
        <div>Assessment: {$assessmentId}</div>
        <div>Instance: {instanceId}</div>
        <div>userName: {$userName}</div>
        <div>UserId: {$userId}</div>
        <div>State: {$assessmentState}</div>
      </div>
    </div>
  </div>

  <div class="card-group">
    {#if exerciseListStatus === "NEW"}
      Loading
    {:else if exerciseListStatus === "LOADING" || definitionStatus === "LOADING"}
      Loading
    {:else if exerciseListStatus === "ERROR" || definitionStatus === "ERROR"}
      Error loading lesson
    {:else if instanceData && definitionData}
      {#if definitionData?.reportType === "NTNU"}
        <ResultReportNtnu {instanceId} {instanceData} />
      {:else if definitionData?.reportType === "NO_DATA"}
        <ResultReportNoData {instanceId} {instanceData} />
      {:else if definitionData?.reportType === "TO_START"}
        <ResultReportNoData {instanceId} {instanceData} />
      {:else if definitionData?.reportType === "FEEDBACK"}
        <ResultReport {instanceId} {instanceData} />
        <FeedbackModal />
      {:else}
        <ResultReport {instanceId} {instanceData} />
      {/if}
    {:else}
      xyz
    {/if}
  </div>
</div>

<style>
  @media (max-width: 768px) {
    .lesson-detail-box {
      display: none;
    }
    .container {
      padding: 0;
    }
  }

  .card-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .lesson-title {
    font-weight: 600;
    font-size: large;
  }

  .lesson-detail {
    border: 1px;
    border-color: #9d9fa2;
    border-style: solid;
    border-radius: 5px;
    margin: 0.5em;
    padding: 0.5em;
  }
</style>
